<template>
	<div class="my-4">
		<h1 class="mb-3 font-weight-bold text-center">Díjak</h1>
		<h2 class="font-weight-bold text-center my-3">I. Helyezett</h2>
		<section class="bg-main py-5 text-center">
			<span class="display-1 text-white">300 000 Ft</span>
		</section>
		<h2 class="font-weight-bold text-center my-3">II. Helyezett</h2>
		<section class="bg-main py-5 text-center">
			<span class="display-1 text-white">150 000 Ft</span>
		</section>
		<h2 class="font-weight-bold text-center my-3">III. Helyezett</h2>
		<section class="bg-main py-5 text-center">
			<span class="display-1 text-white">50 000 Ft</span>
		</section>
		<h2 class="font-weight-bold text-center my-3">Közönségdíj</h2>
		<section class="bg-main py-5 text-center ">
			<span class="display-1 text-white">50.000 Ft</span>
		</section>
	</div>
</template>
<script>
export default {};
</script>
